@import "../../../styles/main.scss";

.auth {
  width: 100%;
  text-align: center;
  color: white;
  height: 20vh;
  @include mobile-device {
    height: auto;
  }
}

.auth h4 {
  font-size: $title-font-size;
  padding-bottom: 5px;
}

.auth input {
  width: 75%;
  border: none;
  margin: 3px 0;
  font-size: $default-font-size;
  line-height: 1.5;
  text-align: center;
  outline: none;
}

.auth .buttonWrapper {
  width: 75%;
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: center;
}

.auth button {
  @include smallButton;
  font-weight: 600;
  padding: 3px 3px;
  flex: 1;
  margin: 3px;
  font-size: $small-font-size;
}

.auth .logout-button {
  @include smallButton;
  font-weight: 600;
  padding: 10px 10px;
  width: 50%;
  font-size: $small-font-size;
  @include mobile-device {
    font-size: $default-font-size;
  }
}
