@import "../../../styles/main.scss";

.faq {
  background: rgba($color: #000000, $alpha: 0.75);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 100;
  display: flex;
}

.textContent {
  margin: auto;
  height: 90%;
  width: 90%;
  background: white;
  border-radius: 20px;
  @include mobile-device {
    overflow-y: scroll;
  }
}

.textWrapper {
  padding: 3vw;
}

.questionWrapper {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  @include mobile-device {
    flex-direction: column;
    flex-wrap: auto;
  }
}

.question {
  width: 25%;
  padding: 10px;
  h4 {
    font-size: 1.2em;
    padding-bottom: 5px;
  }
  p {
    font-size: 1em;
  }
  @include mobile-device {
    width: 100%;
  }
}

.buttonWrapper {
  width: 100%;
  text-align: center;
}

.faq button {
  @include bigButton;
}
