@import "../../../styles/main.scss";

.compactView {
  width: 100%;
  background: $secondary-background-color;
  text-align: center;
  display: flex;
  justify-content: space-around;
  padding: 2vh 5vh;
  @include mobile-device {
    flex-direction: column;
  }
}

.compactView h1 {
  flex: 1;
  padding: 0 1vh;
  color: white;
  margin-bottom: 1vh;
  font-size: 1.7vh;
}

.compactView .compactView-emphasis {
  font-size: 3vh;
  font-weight: 500;
}

.compactView .compactView-goalReached {
  color: orange;
  font-size: 3vh;
  font-weight: 500;
}
