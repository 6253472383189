// MEDIA QUERY MIXINS https://itnext.io/writing-media-queries-with-sass-mixins-3ea591ea3ea4

@mixin mobile-device {
  @media (max-width: 910px) {
    @content;
  }
}

@mixin mobile-device-no-display {
  @media (max-width: 910px) {
    display: none;
  }
}

@mixin weekRow {
  flex: 1;
  height: 72px;
}

@mixin weekLabel-label {
  flex: 1;
  font-weight: 600;
}

@mixin weekRow-label {
  width: 100%;
  border: none;
  outline-color: none;
  background: $main-background-color;
  font-size: $default-font-size;
  text-align: center;
  line-height: 1.5;
  font-weight: 600;
  overflow: hidden;
  @include mobile-device {
    font-size: $small-font-size;
  }
}

@mixin weekRow-data {
  height: 50%;
  width: 100%;
  font-weight: 500;
  border: none;
  outline-color: black;
  font-size: $default-font-size;
  background: $secondary-background-color;
  text-align: center;
  line-height: 1.5;
  @include mobile-device {
    font-size: $small-font-size;
  }
}

@mixin bigButton {
  font-size: $title-font-size;
  border: 1px solid black;
  padding: 10px 10px;
  border-radius: 8px;
  margin: 10px auto;
  background: $button-color;
  outline: none;
  box-shadow: 0 5px #666;
  transition: 0.1s ease-in;
  font-weight: 500;
  &:active {
    box-shadow: 0 2px #666;
    transform: translateY(4px);
  }
  &:hover {
    background-color: lighten($button-color, 5%);
  }
}

@mixin smallButton {
  font-size: $small-font-size;
  border: 1px solid black;
  outline: none;
  padding: 5px;
  border-radius: 8px;
  margin: 5px auto;
  background: $button-color;
  box-shadow: 0 5px #666;
  font-weight: 500;
  transition: 0.1s ease-in;
  &:active {
    box-shadow: 0 2px #666;
    transform: translateY(4px);
  }
  &:hover {
    background-color: darken($button-color, 10%);
  }
}
