@import "../../../../styles/main.scss";

.weekRow-entry {
  @include weekRow;
  color: white;
}

.weekRow-entry input {
  @include weekRow-data;
  color: white;
  cursor: pointer;
}
