@import "../../../styles/main.scss";

.inputColumn {
  border-radius: 20px;
  padding: 10px 0;
  background: $secondary-background-color;
  color: white;
  @include mobile-device {
    margin: 10px 0;
  }
}
