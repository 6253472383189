@import "../../styles/main.scss";

.sideBar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  color: white;
  overflow: hidden;
}
