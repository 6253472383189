@import "../../../styles/main.scss";

.inputColumnWrapper {
  display: flex;
  justify-content: space-evenly;
  background-color: $secondary-background-color;
}

@include mobile-device {
  .inputColumnWrapper {
    flex-direction: column;
    padding: 0 10px;
  }
}
