@import "./styles/main.scss";

.App {
  color: $font-color;
  display: flex;
  flex-direction: row;
  height: 100%;
  @include mobile-device {
    flex-direction: column;
    z-index: 1;
  }
}

.side-bar {
  flex: 1;
  min-width: 250px;
  height: 100vh;
  position: sticky;
  top: 0;
  background: $secondary-background-color;
  @include mobile-device {
    position: static;
    height: auto;
    flex: auto;
  }
}

.main-window {
  flex: 4;
  height: 100%;
  @include mobile-device {
    position: static;
    flex: auto;
  }
}
