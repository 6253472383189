@import "../../../styles/main.scss";

.clock {
  width: 100%;
  height: 250px;
  text-align: center;
  margin: 0 auto;
  outline: none;
  color: white;
  @include mobile-device {
    min-height: 175px;
    height: auto;
  }
}

.clock h1 {
  width: 100%;
  margin: 0 auto 10px;
  padding: 0 5px;
  font-size: $large-font-size;
}

.clock h3 {
  width: 100%;
  margin: 10px auto;
  padding: 0 5px;
  font-size: $title-font-size;
}

.clock-hidden {
  visibility: hidden;
}
