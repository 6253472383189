@import "../../../styles/main.scss";

.weekRow {
  background: $main-background-color;
  border-bottom: 1px solid black;
}

.weekRow-wrapper {
  display: flex;
  text-align: center;
}

@media only screen and (max-width: 910px) {
  .weekRow {
    font-size: $default-font-size-mobile;
  }
  .weekRow-wrapper {
    padding: 0;
  }
}
