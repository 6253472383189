@import "../../../styles/main.scss";

.weekRow {
  background: $secondary-background-color;
  border-bottom: 1px solid black;
}

@include mobile-device {
  .weekRow {
    font-size: $default-font-size-mobile;
  }
  .weekRow-wrapper {
    padding: 0;
  }
  .noMobile {
    display: none;
  }
}
