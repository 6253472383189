@import "../../styles//main.scss";

.floater {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  margin: 5px 5px 0 0;
  text-align: right;
  text-decoration: none;
  color: black;
  p {
    font-size: 10px;
    font-weight: 600;
  }

  @include mobile-device-no-display;
}
