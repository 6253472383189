@import "../../../styles/main.scss";

.inputRow {
  width: 100%;
  height: 40px;
  font-weight: 500;
}

.inputRow-label {
  text-align: left;
  font-size: $default-font-size;
  &:hover {
    color: #c7ea46;
  }
  @include mobile-device {
    height: 30px;
  }
}

.inputRow-input {
  text-align: left;
  padding: 0 0 0 10px;
}

.inputRow-input input {
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 2px;
  outline: none;
  line-height: 1.75;
  &:hover {
    cursor: pointer;
  }
}

.inputRow-units {
  font-size: $small-font-size;
}

@include mobile-device {
  .inputRow-label {
    font-size: $default-font-size-mobile;
  }
  .inputRow {
    height: 20px;
  }
}
