@import "../../../styles/main.scss";

.weekLabel {
  background: $main-background-color;
  position: relative;
  z-index: 2;
  display: block;
  border-bottom: 1px solid black;
  justify-content: center;
  @include mobile-device {
    font-size: $small-font-size;
  }
}

.weekLabel-wrapper {
  display: flex;
  padding: 10px 0;
  text-align: center;
}

.weekLabel-wrapper div {
  @include weekLabel-label;
}

@include mobile-device {
  .noMobile {
    display: none;
  }
}
