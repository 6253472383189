.error {
  width: 100%;
  text-align: center;
}

.error p {
  background-color: white;
  line-height: 1.5;
  color: #f31431;
  font-weight: 600;
  font-size: 15px;
}
