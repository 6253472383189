@import "../../../styles/main.scss";

.middlePanel-wrapper {
  width: 100%;
  text-align: center;
  display: flex;
  padding: 10px;
}

.addWeekBtn {
  @include bigButton;
  @include mobile-device {
    width: 50%;
    margin: 10px;
  }
}

.compactViewBtn {
  @include bigButton;
}
