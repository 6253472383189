@import "../../../styles/main.scss";

.inputRowTitle {
  font-size: $title-font-size;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 910px) {
  .inputRowTitle {
    font-size: $title-font-size-mobile;
    margin-bottom: 5px;
  }
}
