@import "../../../styles/main.scss";

.saveMenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.saveLabel {
  font-size: 11px;
  color: gray;
}

.saveMenu button {
  @include smallButton;
  width: 75%;
  margin: 1vh auto;
  font-size: 14px;
  height: 40px;
  @include mobile-device {
    font-size: 20px;
  }
}

.saveMenu button[disabled] {
  background: darken(lightgray, 10%);
}

@include mobile-device {
  .saveMenu {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .saveMenu button {
    font-size: $default-font-size;
    width: 45%;
    overflow: hidden;
  }

  .saveLabel {
    visibility: hidden;
  }
}
