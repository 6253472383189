$small-font-size: 0.7rem;
$default-font-size: 1rem;
$title-font-size: 1.1rem;
$large-font-size: 2.3rem;

$default-font-size-mobile: 0.8rem;
$title-font-size-mobile: 1rem;

$main-background-color: white;
$cell-background-color: #659dbd;
$secondary-background-color: #659dbd;
$secondary-background-color: #004181;
$font-color: black;
$button-color: #fff;

$font-color: #000000;

$purple-gradient: linear-gradient(to bottom, #7f53ac 0, #657ced 100%);
