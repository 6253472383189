@import "../../styles/main.scss";

.input {
  width: 100%;
  background-color: $main-background-color;
  position: sticky;
  top: 0;
  z-index: 2;
  transition: 0.2s;
  @include mobile-device {
    position: static;
    z-index: 1;
  }
}

.initialInput-table {
  width: 100%;
}

.hidden {
  display: none;
}
