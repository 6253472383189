@import "../../../../styles/main.scss";

.LabelCell {
  @include weekRow;
  @include weekRow-label;
}

.LabelCell div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    @include mobile-device {
      font-size: $small-font-size;
    }
  }
}

.noWrap p {
  overflow: hidden;
  white-space: nowrap;
}

.mobileViewHide {
  @include mobile-device-no-display;
}

.red {
  color: red;
}

.green {
  color: green;
}
